import React, { useEffect, useState } from "react";
import AuthMethods from "app/services";
import SolarTable from "@custom/Components/SolarTable";
import CommonCharts from "./CommonCharts";
import { Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";
import TableMultiSelect from "@custom/Components/TableMultiSelect";

// const today = new Date();

// const year = today.getFullYear();
// let month = today.getMonth() + 1; // getMonth() returns 0 for January, 1 for February, etc.
// let day = today.getDate();

// month = month < 10 ? '0' + month : month;
// day = day < 10 ? '0' + day : day;

// const startDate = `${year}-${month}-${day}`;

const today = new Date();

// n-1
today.setDate(today.getDate() - 1);

const year = today.getFullYear();
let month = today.getMonth() + 1;
let day = today.getDate();

month = month < 10 ? '0' + month : month;
day = day < 10 ? '0' + day : day;

const startDate = `${year}-${month}-${day}`;

function addDaysToDate(dateStr, daysToAdd) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0]; // date in yyyy-mm-dd format
}

const categoryDropdown = [
    'A',
    'B',
];

export default function InventoryTable() {
    const [tableData, setTableData] = useState([]);
    const [allData, setAllData] = useState([]); //for category
    const [allDataGraph, setAllDataGraph] = useState([]); //for graph
    const [isCustomColumnChanged, setIsCustomColumnChanged] = useState(false);
    const [updatedAt, setUpdatedAt] = useState('');
    const [updatedAtDate, setUpdatedAtDate] = useState('');

    const [selectedPlant, setSelectedPlant] = useState('');
    const [allPlantMapping, setAllPlantMapping] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [allCategoryMapping, setAllCategoryMapping] = useState([]);
    
    const [selectedModule, setSelectedModule] = useState('');
    const [allModuleMapping, setAllModuleMapping] = useState([]);
    
    const [selectedMaterialCode, setSelectedMaterialCode] = useState('');
    const [allMaterialCodeMapping, setAllMaterialCodeMapping] = useState([]);

    const [moduleDropdown, setModuleDropdown] = useState([])
    const [plantDropdown, setPlantDropdown] = useState([])
    
    const columns = [
        { 
            title: "Date", 
            field: "date",
            filtering: false, 
            hidden: true
        },
        
        { 
            title: "Plant Name", 
            field: "plant_name",
            filtering: true,
            cellStyle: {
                width: 30,
                maxWidth: 30,
                border: '2px solid #ddd', 
                textAlign: 'center', 
                padding: '0px',
                fontSize: '12px',
              },
              headerStyle: {
                width:30,
                maxWidth: 30,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '5px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              },
            customFilterAndSearch: (data, rowData) => {
            return data.includes(rowData.plant_name);
            },
            filterComponent: ({ columnDef, onFilterChanged }) => (
            <TableMultiSelect 
                dropdown={plantDropdown} 
                columnDef={columnDef} 
                onFilterChanged={onFilterChanged} 
            />
            )
        },
        
        { 
            title: "Category", 
            field: "material_category",
            filtering: true,
            cellStyle: {
                width: 10,
                maxWidth: 10,
                border: '2px solid #ddd', 
                textAlign: 'center', 
                padding: '0px',
                fontSize: '12px',
              },
              headerStyle: {
                width:10,
                maxWidth: 10,
                textAlign: 'center', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              },
            customFilterAndSearch: (data, rowData) => {
            return data.includes(rowData.material_category);
            },
            filterComponent: ({ columnDef, onFilterChanged }) => (
            <TableMultiSelect 
                dropdown={categoryDropdown} 
                columnDef={columnDef} 
                onFilterChanged={onFilterChanged} 
            />
            )
        },
        
        { 
            title: "Module Type", 
            field: "module_type",
            filtering: true,
            cellStyle: {
                width: 150,
                maxWidth: 150,
                border: '2px solid #ddd', 
                textAlign: 'center', 
                padding: '0px',
                fontSize: '12px',
              },
              headerStyle: {
                width:150,
                maxWidth: 150,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              },
            customFilterAndSearch: (data, rowData) => {
            return data.includes(rowData.module_type);
            },
            filterComponent: ({ columnDef, onFilterChanged }) => (
            <TableMultiSelect 
                dropdown={moduleDropdown} 
                columnDef={columnDef} 
                onFilterChanged={onFilterChanged} 
            />
            )
        },
        { 
          title: "Material Code",
          field: "material_code",
          filtering: false, 
        },
    
        { 
          title: "Material Description", 
          field: "material_description",
          filtering: false, 
        },
        { 
            title: "Stock Quantity", 
            field: "stock_qty",
            filtering: false, 
            hidden: true,
            cellStyle: {
                width: 100,
                maxWidth: 100,
                border: '2px solid #ddd', 
                textAlign: 'center', 
                padding: '0px',
                fontSize: '12px',
              },
              headerStyle: {
                width:100,
                maxWidth: 100,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              },
        },
        { 
            title: "Stock (MW)", 
            field: "stock",
            filtering: false,  
            cellStyle: {
                width: 100,
                maxWidth: 100,
                border: '2px solid #ddd', 
                textAlign: 'center', 
                padding: '0px',
                fontSize: '12px',
              },
              headerStyle: {
                width:80,
                maxWidth: 80,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              },
        },
        
        { 
            title: "7 Days", 
            field: "seven_days",
            filtering: false,
              headerStyle: {
                width:80,
                maxWidth: 80,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              }, 
            cellStyle: (value, rowData) => {
                const isBelowMinimum = rowData.seven_days < rowData.seven_min;

                if(rowData.seven_days == '') {
                    return {
                        backgroundColor: '#FFF',
                        border: '2px solid #ddd',
                    }
                } else {
                    return {
                        backgroundColor: isBelowMinimum ? '#f8866d' : '#a5d686',
                        border: '2px solid #ddd',
                        textAlign: 'center',
                        color: '#FFF'
                      };
                }
            }

        },
        
        { 
            title: "7 Days Min. Inventory", 
            field: "seven_min",
            filtering: false, 
            hidden: true,
        },
        { 
            title: "7 Days Max. Inventory", 
            field: "seven_max",
            filtering: false, 
            hidden: true
        },
        { 
            title: "14 Days", 
            field: "fourteen_days",
            filtering: false, 
            headerStyle: {
                width:80,
                maxWidth: 80,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              }, 
            cellStyle: (value, rowData) => {
                const isBelowMinimum = rowData.fourteen_days < rowData.fourteen_min;
          
                if(rowData.fourteen_days == '') {
                    return {
                        backgroundColor: '#FFF',
                        border: '2px solid #ddd',
                    }
                } else {
                    return {
                        backgroundColor: isBelowMinimum ? '#f8866d' : '#a5d686',
                        border: '2px solid #ddd',
                        textAlign: 'center',
                        color: '#FFF'
                      };
                }
            }
        },
        
        { 
            title: "14 Days Min. Inventory", 
            field: "fourteen_min",
            filtering: false, 
            hidden: true
        },
        
        { 
            title: "14 Days Max. Inventory", 
            field: "fourteen_max",
            filtering: false, 
            hidden: true
        },
        { 
            title: "21 Days", 
            field: "twenty_one_days",
            filtering: false, 
            headerStyle: {
                width:80,
                maxWidth: 80,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              }, 
            cellStyle: (value, rowData) => {
                const isBelowMinimum = rowData.twenty_one_days < rowData.twenty_one_min;
          
                if(rowData.twenty_one_days == '') {
                    return {
                        backgroundColor: '#FFF',
                        border: '2px solid #ddd',
                    }
                } else {
                    return {
                        backgroundColor: isBelowMinimum ? '#f8866d' : '#a5d686',
                        border: '2px solid #ddd',
                        textAlign: 'center',
                        color: '#FFF'
                      };
                }
            }
        },
        
        { 
            title: "21 Days Min. Inventory", 
            field: "twenty_one_min",
            filtering: false, 
            hidden: true
        },
        
        { 
            title: "21 Days Max. Inventory", 
            field: "twenty_one_max",
            filtering: false, 
            hidden: true
        },
        { 
            title: "28 Days", 
            field: "twenty_eight_days",
            filtering: false, 
            headerStyle: {
                width:80,
                maxWidth: 80,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '10px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              }, 
            cellStyle: (value, rowData) => {
                const isBelowMinimum = rowData.twenty_eight_days < rowData.twenty_eight_min;
          
                if(rowData.twenty_eight_days == '') {
                    return {
                        backgroundColor: '#FFF',
                        border: '2px solid #ddd',
                    }
                } else {
                    return {
                        backgroundColor: isBelowMinimum ? '#f8866d' : '#a5d686',
                        border: '2px solid #ddd',
                        textAlign: 'center',
                        color: '#FFF'
                      };
                }
            }
        },
        
        { 
            title: "28 Days Min. Inventory", 
            field: "twenty_eight_min",
            filtering: false, 
            hidden: true
        },
        
        { 
            title: "28 Days Max. Inventory", 
            field: "twenty_eight_max",
            filtering: false, 
            hidden: true
        },
        { 
            title: "Custom Input", 
            field: "custom_days",
            filtering: false, 
            headerStyle: {
                width:80,
                maxWidth: 80,
                textAlign: 'center', 
                border: '2px solid #ddd', 
                padding: '5px',
                whiteSpace: 'nowrap',
                fontSize: '10px'
              }, 
            cellStyle: (value, rowData) => {
                if (isCustomColumnChanged) {
                    const isBelowMinimum = rowData.custom_days < rowData.custom_min;
          
                    return {
                      backgroundColor: isBelowMinimum ? '#f8866d' : '#a5d686',
                      border: '2px solid #ddd',
                      textAlign: 'center',
                      color: '#FFF'
                    };
                } else {
                    return {
                        border: '2px solid #ddd',
                        textAlign: 'center',
                        color: '#FFF'
                      };
                }
            }
        },
        
        { 
            title: "Custom Min. Inventory", 
            field: "custom_min",
            filtering: false, 
            hidden: true
        },
        
        { 
            title: "Custom Max. Inventory", 
            field: "custom_max",
            filtering: false, 
            hidden: true
        },
        
        { 
            title: "Updated at", 
            field: "updated_at",
            filtering: false, 
            hidden: true,
            hiddenByColumnsButton: true
        },
      ];

      const columnsWithBorders = columns.map(column => ({
        ...column,
        cellStyle: column.cellStyle ? column.cellStyle : {
          border: '2px solid #ddd', 
          textAlign: 'center', 
          padding: '0px',
          fontSize: '12px',
        },
        headerStyle: column.headerStyle ? column.headerStyle : {
          textAlign: 'center',
          border: '2px solid #ddd',
          fontSize: '10px',
          whiteSpace: 'nowrap'
        }
      }));
      
    //   getSubCategory(allData, 'plant_name', 'Jaipur', 'material_category')
    function getSubCategory(allData, keyToMatch, valueToMatch, subCategoryKey) {
        const filteredData = allData.filter(item => item[keyToMatch] === valueToMatch);
        const uniqueSubCategoryValues = [...new Set(filteredData.map(item => item[subCategoryKey]))];
        
        const formattedData = uniqueSubCategoryValues.map(subCategoryValue => ({
          [subCategoryKey]: subCategoryValue
        }));
        
        return formattedData;
    }

    function mapCodesToDescriptions(allData, allMaterialCodeMapping) {
        const mappedArray = [];
      
        allMaterialCodeMapping.forEach(codeObj => {
          const dataEntry = allData.find(item => item.material_code === codeObj.material_code);
      
          if (dataEntry) {
            mappedArray.push({
              [codeObj.material_code]: dataEntry.material_description
            });
          }
        });
      
        return mappedArray;
    }

    useEffect(() => {
      AuthMethods['inventory'].getAllCategory().then(data => {
        if (data.length > 0 ) {
            setAllData(data);

            let allPlants = data.reduce((acc, item) => {
                if (!acc.some(accItem => accItem.plantName === item.plant_name)) {
                  acc.push({ plantName: item.plant_name });
                }
                return acc;
            }, []);
            setAllPlantMapping(allPlants);

            setSelectedPlant(allPlants[0]['plantName']);
            let categories = getSubCategory(data, 'plant_name', allPlants[0]['plantName'], 'material_category')
            setAllCategoryMapping(categories);
        }
      });
      
    },[])

    useEffect(() => {
        function fetchAndSetInventoryData(startDate) {
            const dates = {
              sevenDays: addDaysToDate(startDate, 6),
              fourteenDays: addDaysToDate(startDate, 13),
              twentyOneDays: addDaysToDate(startDate, 20),
              twentyEightDays: addDaysToDate(startDate, 27),
            };
          
            AuthMethods['inventory'].getInventoryByDate(startDate).then(data => {
              if (data.length > 0) {
                let temp = [];
          
                for (let i = 0; i < data.length; i++) {
                  const promises = [
                    AuthMethods['inventory'].getInventoryByDate(dates.sevenDays),
                    AuthMethods['inventory'].getInventoryByDate(dates.fourteenDays),
                    AuthMethods['inventory'].getInventoryByDate(dates.twentyOneDays),
                    AuthMethods['inventory'].getInventoryByDate(dates.twentyEightDays),
                  ];
          
                  Promise.all(promises).then(([sevenDaysData, fourteenDaysData, twentyOneDaysData, twentyEightDaysData]) => {
                    const mappedData = data[i].data.map((item, index) => {
                      return {
                        id: index,
                        date: data[i].date,
                        plant_name: data[i].plant_name,
                        material_category: item.Material_Category,
                        material_code: item.Material_Code,
                        material_description: item.Material_Description,
                        module_type: item.Module_Type,
                        stock_qty: parseFloat(item['Unrestricted_Stock_Qty'] || 0), 
                        stock: parseFloat(item['Unrestricted_Stock_MW'] || 0),

                        seven_days: sevenDaysData && typeof sevenDaysData === 'object' && sevenDaysData.length > i ? parseFloat(sevenDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0) : '--',
                        fourteen_days: fourteenDaysData.length > i ? parseFloat(fourteenDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0) : '--',
                        twenty_one_days: twentyOneDaysData.length > i ? parseFloat(twentyOneDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0) : '--',
                        twenty_eight_days: twentyEightDaysData.length > i ? parseFloat(twentyEightDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0) : '--',
                        
                        seven_min: sevenDaysData.length > i ? parseFloat(sevenDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0) : null,
                        fourteen_min: fourteenDaysData.length > i ? parseFloat(fourteenDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0) : null,
                        twenty_one_min: twentyOneDaysData.length > i ? parseFloat(twentyOneDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0) : null,
                        twenty_eight_min: twentyEightDaysData.length > i ? parseFloat(twentyEightDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0) : null,

                        
                        seven_max: sevenDaysData.length > i ? parseFloat(sevenDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0) : null,
                        fourteen_max: fourteenDaysData.length > i ? parseFloat(fourteenDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0) : null,
                        twenty_one_max: twentyOneDaysData.length > i ? parseFloat(twentyOneDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0) : null,
                        twenty_eight_max: twentyEightDaysData.length > i ? parseFloat(twentyEightDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0) : null,

                        updated_at: data[i].updatedAt,
                      };
                    });
          
                    temp.push(...mappedData);

                    const uniqueModuleTypes = [...new Set(temp.map(item => item.module_type))];
                    setModuleDropdown(uniqueModuleTypes)
                    const uniquePlantNames = [...new Set(temp.map(item => item.plant_name))];
                    setPlantDropdown(uniquePlantNames)

                    //to set all subsequent fields to null/empty if -ve value found
                    temp.forEach(item => {
                        let fields = ['seven_days', 'fourteen_days', 'twenty_one_days', 'twenty_eight_days'];
                        let minfields = ['seven_min', 'fourteen_min', 'twenty_one_min', 'twenty_eight_min'];

                        for (let i = 0; i < fields.length; i++) {
                            // if (parseFloat(item[fields[i]]) < 0) {
                            if (parseFloat(item[fields[i]]) < parseFloat(item[minfields[i]])) {
                                for (let j = i + 1; j < fields.length; j++) {
                                    item[fields[j]] = '';
                                }
                                break;
                            }
                        }
                    });

                    const updatedAt = temp[0]['updated_at'];
                    const formattedTime = dayjs(updatedAt).format('HH:mm:ss');
                    const formattedDate = dayjs(updatedAt).format('YYYY-MM-DD');
                    setUpdatedAt(formattedTime)
                    setUpdatedAtDate(formattedDate)
                    
                    setTableData(temp);
                  }).catch(error => {
                    console.error('Error fetching additional inventory data:', error);
                  });
                }
              }
            }).catch(error => {
              console.error('Error fetching initial inventory data:', error);
            });
          }

          fetchAndSetInventoryData(startDate);
    },[])

    useEffect(() => {
        if(selectedMaterialCode != '') {
            AuthMethods['inventory'].getAllGraphData(selectedMaterialCode, startDate).then(data => {
                if (data.length > 0 ) {
                    setAllDataGraph(data);
                }
            });
        }
    },[selectedMaterialCode])

    return(
        <div>
            <Typography variant={"h1"} sx={{
                            fontWeight: 'bold',
                            color: theme => theme.palette.secondary.main,
                            marginBottom: '20px'
                        }}>Inventory Table
            </Typography>
            {tableData.length != 0 && <SolarTable
                title={`Last Updated: ${updatedAtDate} | ${updatedAt}`}
                columns={columnsWithBorders}
                data={tableData}
                filtering={true}
                isInventoryTable={true}
                // tableLayout={'fixed'}
                
                setTableData={setTableData}
                startDate={startDate}
                setIsCustomColumnChanged={setIsCustomColumnChanged}

                // loading={loading}
                actions={[
                // {
                //     icon: () => {
                //     return <Edit 
                //         fontSize="small" 
                //         sx={{ color: theme => theme.palette.info.main }}
                //     />;
                //     },
                //     tooltip: "Edit User",
                //     onClick: (event, rowData) => {
                //     handleEditUser(rowData);
                //     }
                // },
                // {
                //     icon: () => {
                //     return <Delete 
                //         fontSize="small" 
                //         sx={{ color: theme => theme.palette.error.main }} 
                //     />;
                //     },
                //     tooltip: "Delete User",
                //     onClick: (event, rowData) => {
                //     handleDeleteUser(rowData);
                //     } 
                // },
                // rowData => ({
                //     icon: () => {
                //     return <Key 
                //         fontSize="small" 
                //         sx={rowData['status'] === 'Active' ? (
                //         { color: theme => theme.palette.primary.main }
                //         ) : (
                //             { color: theme => theme.palette.error.main }
                //         )}
                //     />;
                //     },
                //     tooltip: rowData['status'] === 'Active' ? 'Inactivate User' : 'Activate User', 
                //     onClick: (event, rowData) => {
                //     if (rowData['status'] === 'Active') {
                //         return InactivateUser(rowData);
                //     } else {
                //         ActivateUser(rowData);
                //     }
                //     }
                // }),
                ]}
                detailPanel={[]}
            />}
            <Typography variant={"h1"} sx={{
                            fontWeight: 'bold',
                            color: theme => theme.palette.secondary.main,
                            margin: '50px 0 20px 0'
                        }}>Inventory Chart
            </Typography>
            <Grid container spacing={2} sx={{marginBottom: '30px'}}>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '13px', fontWeight: 'bold', pb: '3px' }}
                    >
                        Plant Name
                    </InputLabel>
                    <Select
                    sx={{width: '100%'}}
                    name="plantName"
                    value={selectedPlant}
                    onChange={(e) => {
                        setSelectedPlant(e.target.value);
                        let categories = getSubCategory(allData, 'plant_name', e.target.value, 'material_category')
                        setAllCategoryMapping(categories);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    >
                        <MenuItem value="" disabled>
                        Select Plant
                        </MenuItem>
                        {allPlantMapping.map((option) => (
                        <MenuItem key={option.plantName} value={option.plantName}>
                            {option.plantName}
                        </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '13px', fontWeight: 'bold', pb: '3px' }}
                    >
                        Material Category
                    </InputLabel>
                    <Select
                    sx={{width: '100%'}}
                    name="materialCategory"
                    value={selectedCategory}
                    onChange={(e) => {
                        setSelectedCategory(e.target.value);
                        let categories = getSubCategory(allData, 'material_category', e.target.value, 'module_type')
                        setAllModuleMapping(categories);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    >
                        <MenuItem value="" disabled>
                        Select Category
                        </MenuItem>
                        {allCategoryMapping.map((option) => (
                        <MenuItem key={option['material_category']} value={option['material_category']}>
                            {option['material_category']}
                        </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '13px', fontWeight: 'bold', pb: '3px' }}
                    >
                        Module Type
                    </InputLabel>
                    <Select
                    sx={{width: '100%'}}
                    name="moduleType"
                    value={selectedModule}
                    onChange={(e) => {
                        setSelectedModule(e.target.value);
                        let categories = getSubCategory(allData, 'module_type', e.target.value, 'material_code')
                        let allCodes = mapCodesToDescriptions(allData, categories);

                        setAllMaterialCodeMapping(allCodes);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    >
                        <MenuItem value="" disabled>
                        Select Module
                        </MenuItem>
                        {allModuleMapping.map((option) => (
                        <MenuItem key={option['module_type']} value={option['module_type']}>
                            {option['module_type']}
                        </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '13px', fontWeight: 'bold', pb: '3px' }}
                    >
                        Material Code
                    </InputLabel>
                    <Select
                    sx={{width: '100%'}}
                    name="materialCode"
                    value={selectedMaterialCode}
                    onChange={(e) => setSelectedMaterialCode(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    >
                        <MenuItem value="" disabled>
                        Select Description
                        </MenuItem>
                        {allMaterialCodeMapping.map((option) => {
                            const [key, value] = Object.entries(option)[0];

                            return (
                                <MenuItem key={key} value={key}>
                                {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            </Grid>


            <CommonCharts
                // setChartDataChanged={setChartDataChanged}
                allGraphData={allDataGraph}
            />
        </div>
    )
}