import React, { useEffect, useState } from "react";
import SolarTable from "@custom/Components/SolarTable";
import { Delete, Edit, Key } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import CustomButton from "@custom/Components/CustomButton";
import CustomDialog from "@custom/Components/CustomDialog";
import AddEditUser from "./AddEditUser";
import TableMultiSelect from "@custom/Components/TableMultiSelect";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AuthMethods from "app/services";

const statusDropdown = [
  'Active',
  'Inactive',
];


const today = new Date();

const year = today.getFullYear();
let month = today.getMonth() + 1; // getMonth() returns 0 for January, 1 for February, etc.
let day = today.getDate();

month = month < 10 ? '0' + month : month;
day = day < 10 ? '0' + day : day;

const startDate = `${year}-${month}-${day}`;

export default function Users() {

  const MySwal = withReactContent(Swal);

  const [openDialog, setOpenDialog] = useState(false);
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rolesDropdown, setRolesDropdown] = useState([]);
  const [tableDataChanged, setTableDataChanged] = useState(false);
  const [userId, setUserId] = useState('');

  const columns = [

    { 
      title: "ID", 
      field: "id",
      filtering: false, 
      hidden: true, 
      hiddenByColumnsButton: true 
    },

    { 
      title: "Name",
      field: "name",
      filtering: false, 
    },

    { 
      title: "Email", 
      field: "email",
      filtering: false, 
    },

    { 
      title: "Employee Id", 
      field: "empId",
      filtering: false, 
      hidden: true
    },

    { 
      title: "Roles", 
      field: "roles",
      filtering: true,
      customFilterAndSearch: (filterData, rowData) => {
        let flag = false;
        rowData.roles.forEach(item => {
          if (filterData.includes(item.name)) {
            flag = true; 
          }
        })
        return flag;
      },
      filterComponent: ({ columnDef, onFilterChanged }) => (
        <TableMultiSelect 
          dropdown={rolesDropdown} 
          columnDef={columnDef} 
          onFilterChanged={onFilterChanged} 
        />
      ),
      render: rowData => {
        // console.log(rowData);
        let arr = [];
        rowData.roles.forEach(item => {
          arr.push(item.name);
        });
        return arr.join(", ");
      }
    },

    { 
      title: "Designation", 
      field: "designation",
      filtering: false, 
      hidden: true
    },

    { 
      title: "Status", 
      field: "status",
      filtering: true,
      customFilterAndSearch: (data, rowData) => {
        return data.includes(rowData.status);
      },
      filterComponent: ({ columnDef, onFilterChanged }) => (
        <TableMultiSelect 
          dropdown={statusDropdown} 
          columnDef={columnDef} 
          onFilterChanged={onFilterChanged} 
        />
      )
    },

    { 
      title: "Created on", 
      field: "createdOn",
      filtering: false, 
      hidden: true, 
      hiddenByColumnsButton: false 
    },

    { 
      title: "Updated on", 
      field: "updatedOn",
      filtering: false, 
      hidden: true, 
      hiddenByColumnsButton: false 
    },

    { 
      title: "Blocked on", 
      field: "blockedOn",
      filtering: false,
      hidden: true, 
      hiddenByColumnsButton: false 
    },

    { 
      title: "Recovered on", 
      field: "recoveredOn",
      filtering: false,
      hidden: true, 
      hiddenByColumnsButton: false 
    },

  ];

  const handleDialogOpen = () => {
    setAction('add');
    setOpenDialog(true);
  };

  const handleEditUser = (rowData) => {
    setAction('edit');
    setOpenDialog(true);
    setUserId(rowData.id);
  };

  const handleDeleteUser = (rowData) => {
    MySwal.fire({
      title: 'Are you sure to delete this user?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
  }).then(result => {
      if (result.value) {
        setLoading(true);        
        AuthMethods['users'].deleteUser(rowData.id)
          .then(data => {

            let tempData = [...tableData];
            tempData = tempData.filter(item => 
              item.id !== rowData.tableData.id
            );

            setTableData(tempData);
            Swal.fire('Deleted!', 'User has been deleted', 'success');
            setLoading(false);
          })
          .catch(error => {
            Swal.fire('Error occured while deleting', '');
            setLoading(false);
          });
      } else if (
          result.dismiss === Swal.DismissReason.cancel
      ) {
          Swal.fire('Cancelled!', 'User has not been deleted', 'error');
      }
    });
  };

  const InactivateUser = (rowData) => {
    MySwal.fire({
      title: 'Are you sure to inactivate this user?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, inactivate!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
  }).then(result => {
      if (result.value) {
        setLoading(true);
        AuthMethods['users'].changeUserStatus(rowData.id)
        .then(data => {
                    
          let tempData = [...tableData];
          tempData[rowData['index']]['status'] = 'Inactive';
          setTableData(tempData);

          Swal.fire('Inactivated!', 'User has been inactivated', 'success');
          setTableDataChanged(prev => !prev);
          setLoading(false);
        })
        .catch(error => {
          Swal.fire('Error occured', '');
          setLoading(false);
        });
      } else if (
          result.dismiss === Swal.DismissReason.cancel
      ) {
          Swal.fire('Cancelled!', 'User has not been inactivated', 'error');
      }
    });
  };

  const ActivateUser = (rowData) => {
    MySwal.fire({
      title: 'Are you sure to activate this user?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, activate!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
  }).then(result => {
      if (result.value) {
        setLoading(true);
        AuthMethods['users'].changeUserStatus(rowData.id)
          .then(data => {

            let tempData = [...tableData];
            tempData[rowData['index']]['status'] = 'Active';
            setTableData(tempData);

            Swal.fire('Activated!', 'User has been activated', 'success');
            setTableDataChanged(prev => !prev);
            setLoading(false);
        })
        .catch(error => {
          Swal.fire('Error occured', '');
          setLoading(false);
        });
      } else if (
          result.dismiss === Swal.DismissReason.cancel
      ) {
          Swal.fire('Cancelled!', 'User has not been activated', 'error');
      }
    });
  };

  useEffect(() => {
    setLoading(true);

    AuthMethods['roles'].getAllRoles(setLoading).then(data => {
      // console.log(data);
      let arr = [];
      if (data['data'].length > 0) {
        data['data'].forEach(item => {
          arr.push(item.name);
        });
        setRolesDropdown(arr);
      }
    });

    AuthMethods['users'].getAllUsers(setLoading).then(data => {
      // console.log(data);
      if (data['data'].length > 0 ) {
        let temp = data['data'].map((item, index) => {
          return {
            index: index,
            id: item.id,
            name: item.name,
            email: item.email,
            empId: item.employee_code,
            roles: item.role,
            designation: item.designation,
            status: item.status === true ? 'Active' : 'Inactive',
            createdOn: item.created_at,
            updatedOn: item.updated_at,
            blockedOn: item.blocked_at,
            recoveredOn: item.recovered_at
          }
        });
        // console.log(temp);
        setTableData(temp);
      }
    });
  }, [tableDataChanged]);

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box>
          <Typography
            sx={{ 
              color: theme => theme.palette.text.primary,
              fontSize: '21px',
              fontWeight: 'bold'
            }}
          >
              Users
          </Typography>
        </Box>
        <Box>
          <CustomButton
            variant="contained"
            color="primary"
            icon="getPersonAddIcon"
            onClick={handleDialogOpen}
          >
            Add User
          </CustomButton>
        </Box>
      </Box>

      <SolarTable
        title="Users Table"
        columns={columns}
        data={tableData}
        filtering={true}

        startDate={startDate}

        loading={loading}
        actions={[
          {
            icon: () => {
              return <Edit 
                fontSize="small" 
                sx={{ color: theme => theme.palette.info.main }}
              />;
            },
            tooltip: "Edit User",
            onClick: (event, rowData) => {
              handleEditUser(rowData);
            }
          },
          {
            icon: () => {
              return <Delete 
                fontSize="small" 
                sx={{ color: theme => theme.palette.error.main }} 
              />;
            },
            tooltip: "Delete User",
            onClick: (event, rowData) => {
              handleDeleteUser(rowData);
            } 
          },
          rowData => ({
            icon: () => {
              return <Key 
                fontSize="small" 
                sx={rowData['status'] === 'Active' ? (
                  { color: theme => theme.palette.primary.main }
                  ) : (
                    { color: theme => theme.palette.error.main }
                  )}
              />;
            },
            tooltip: rowData['status'] === 'Active' ? 'Inactivate User' : 'Activate User', 
            onClick: (event, rowData) => {
              if (rowData['status'] === 'Active') {
                return InactivateUser(rowData);
              } else {
                ActivateUser(rowData);
              }
            }
          }),
        ]}
        detailPanel={[]}
      />

      <CustomDialog openDialog={openDialog} maxWidth="sm">
        <AddEditUser setOpenDialog={setOpenDialog} action={action} setTableDataChanged={setTableDataChanged} userId={userId} />
      </CustomDialog>
    </React.Fragment>
  );
}
