import Auth from "./auth";
import Users from "./admin/users";
import Roles from "./admin/roles";
import Inventory from "./inventory";

const AuthMethods = {
    auth: Auth,
    users: Users,
    roles: Roles,
    inventory: Inventory,
};

export default AuthMethods;