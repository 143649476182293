import React from "react";
import Page from "@jumbo/shared/Page";
import { Navigate } from "react-router-dom";
import Login from "app/pages/auth-pages/login/Login";
import Error404 from "app/pages/extra-pages/Error404";
import Home from "app/pages/home/Home";
import Users from "app/pages/Users";
import InventoryTable from "app/pages/InventoryTable";
import Metadata from "app/pages/Metadata";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"}/>
        // element: <Login />
    },
    {
        path: "*",
        element: <Page component={Error404} layout={"solo-page"} />
    }
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboard"} />
    },
    {
        path: "/dashboard",
        element: <Page component={Home} />
    },
    {
        path: "/users",
        element: <Page component={Users} />
    },
    {
        path: "/metadata",
        element: <Page component={Metadata} />
    },
    {
        path: "/inventorytable",
        element: <Page component={InventoryTable} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"} />
        // element: <Login />
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};