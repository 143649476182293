import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';

more(Highcharts);

export default function ColumnHighcharts(props) {

     const options = {
          credits: {
               enabled: false
          },
          chart: {
               events: {
                    render: function() {
                      this.reflow();
                    },
                    redraw: function(event) {
                      // when api is not working
                      if (this.series.length === 0) {
                        this.showLoading('Cannot Load Data Currently');
                      } else if (this.series[0].data.length === 0) {
                        // no data 
                        this.showLoading('No Data');
                      } else {
                        this.hideLoading();
                      }
                    },
               },
               ...props.chartData.chart,
          },
          title: {
               style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#666A7B',
               },
               ...props.chartData.title
          },
          subtitle: props.chartData.subtitle,
          colors: ['#A5D686', '#F8866D', '#006638', '#FE6A35'],
          xAxis: props.chartData.xAxis,
          yAxis: props.chartData.yAxis,
          tooltip: props.chartData.tooltip,
          legend: props.chartData.legend,
          plotOptions: props.chartData.plotOptions,
          series: props.chartData.series,
     };
     
     return (

          <HighchartsReact highcharts={Highcharts} options={options} />
     );
}
