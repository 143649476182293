import React, { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import ChartLoader from '@custom/Charts/ChartLoader';
import ColumnHighcharts from '@custom/Charts/ColumnHighcharts';
import AuthMethods from 'app/services';
import _ from 'lodash';
import Highcharts from 'highcharts';

const today = new Date();

const year = today.getUTCFullYear();
const month = today.getUTCMonth();
const day = today.getUTCDate();

export default function CommonCharts(props) {

     const [loading, setLoading] = useState(false);
     const [healthChartData, setHealthChartData] = useState([]);
     const [highStockData, setHighStockData] = useState([]);
     const [lowStockData, setLowStockData] = useState([]);
     const [lineData, setLineData] = useState([]);

     function setDataForHighGraph() {
        let graphData = props.allGraphData.map(item => {
            return [
                parseFloat(item['Max_Inventory_Required_MW']),
                parseFloat(item['Min_Inventory_Required_MW'])
            ]
        })
        return graphData
     }

     function setDataForLowGraph() {
        let graphData = props.allGraphData.map(item => {
            return [
                parseFloat(item['Min_Inventory_Required_MW']),
                0
            ]
        })
        return graphData
     }

     function setDataForLineGraph() {
        let graphData = props.allGraphData.map(item => {
            return item['Unrestricted_Stock_MW'] < 0 ? 0 : parseFloat(item['Unrestricted_Stock_MW'])
        })
        return graphData
     }

     useEffect(() => {
        let maxAreaGraphData = setDataForHighGraph();
        setHighStockData(maxAreaGraphData);

        let minAreaGraphData = setDataForLowGraph();
        setLowStockData(minAreaGraphData);

        
        let lineGraphData = setDataForLineGraph();
        setLineData(lineGraphData);

     },[props.allGraphData])

     const healthChart = {

          chart: {
               type: 'column'
          },
          title: {
               text: 'Inventory Stock Report'
          },
          xAxis: {
               type: 'datetime',
          },
          yAxis: {
               // min: 0,
               title: {
                    text: 'Inventory Stock(MWp)'
               }
          },
        //   tooltip: {
        //        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b>'
        //   },
        tooltip: {
            formatter: function() {
              //'%Y-%m-%d' formats the date as "YYYY-MM-DD"
              const dateStr = Highcharts.dateFormat('%Y-%m-%d', this.x);
          
              // Line series
              let tooltipFormat = `<b>Date: ${dateStr}</b><br/><span style="color:${this.color}">${this.series.name}</span>: <b>${this.y.toFixed(2)}</b><br/>`;
          
              // Arearange series
              if (this.point.low !== undefined && this.point.high !== undefined) {
                  tooltipFormat = `<b>Date: ${dateStr}</b><br/><span style="color:#000000">${this.series.name}</span>: <br/>High: <b>${this.point.low.toFixed(2)}</b><br/>Low: <b>${this.point.high.toFixed(2)}</b>`;
              }
          
              return tooltipFormat;
            }
          },
          legend: {
               enabled: true,
          },
          plotOptions: {
               column: {
                    colorByPoint: true
               },
               series: {
                    borderWidth: 0,
                    pointStart: Date.UTC(year, month, day), 
                    pointIntervalUnit: 'day',
                    dataLabels: {
                         enabled: true,
                         format: '{point.y:.1f}'
                    }
               }
          },

          series: [
            {
               type: 'line',
               name: 'Actual Stock',
               data: lineData
            },
            {
               type: 'arearange',
               name: 'Maximum Stock',
               data: highStockData,
               dataLabels: {
                enabled: false
               }
            },
            {
               type: 'arearange',
               name: 'Minimum Stock',
               data: lowStockData,
               dataLabels: {
                   enabled: false
               }
            }
    ]
     };

    //  useEffect(() => {

    //       if (props.chartDataChanged === 1) {
    //            setLoading(true);
          
    //            let chartData = {
    //                 'audit_plan_data_id': parseInt(props.auditPlanDataId),
    //                 'audit_checklist_category_id': props.checklistCategory,
    //            };
     
    //            AuthMethods['common'].getHealthScoreChart(chartData, setLoading).then(data => {
    //                 // console.log(data);
    //                 if (_.size(data['data']) > 0) {
                         
    //                      setHealthChartData(
    //                           [{
    //                                data: [
    //                                     {
    //                                          name: 'Current',
    //                                          y: parseFloat(data['data']['current']),
    //                                     }, 
    //                                     {
    //                                          name: 'Initial',
    //                                          y: parseFloat(data['data']['initial']),
    //                                     }
    //                                ]
    //                           }]
    //                      );
    //                 }
    //            });
     
    //            AuthMethods['common'].getSeverityChart(chartData, setLoading).then(data => {
    //                 // console.log(data);
    //                 if ((data['data']).length > 0) {
    //                      if (_.size(data['data'][0]) > 0) {
     
    //                           setSeverityChartData(
    //                                [{
    //                                     data: [
    //                                          {
    //                                               name: 'High',
    //                                               y: data['data'][0]['high'],
    //                                               color: '#E95363',
    //                                          }, 
    //                                          {
    //                                               name: 'Medium',
    //                                               y: data['data'][0]['mid'],
    //                                               color: '#F4A738',
    //                                          },
    //                                          {
    //                                               name: 'Low',
    //                                               y: data['data'][0]['low'],
    //                                               color: '#7b9bea',
    //                                          }
    //                                     ]
    //                                }]
    //                           );
    //                      }
    //                 }
    //            });

    //            props.setChartDataChanged(0);
    //       }

    //  }, [props.chartDataChanged]);

    //  useEffect(() => {
    //       if (props.auditPlanDataId !== '') {
    //            setLoading(true);
               
    //            AuthMethods['common'].getSiteHealthScore(parseInt(props.auditPlanDataId)).then(data => {
    //                 if (_.size(data['audited_data']) > 0) {
    //                      setHealthChartData(
    //                           [{
    //                                data: [
    //                                     {
    //                                          name: 'Current',
    //                                          y: parseFloat(data['audited_data']['current']),
    //                                     }, 
    //                                     {
    //                                          name: 'Initial',
    //                                          y: parseFloat(data['audited_data']['initial']),
    //                                     }
    //                                ]
    //                           }]
    //                      );
    //                      setLoading(false);
    //                 }
    //            });
    //       }
    //  }, [props.auditPlanDataId]);

     return (

          <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                    <Card sx={{ p: 1, boxShadow: "0px 0px 10px 1px #DBD8F1", height: '100%', maxHeight: '420px' }}>
                         {loading ? (<ChartLoader title="Loading..." />) : (
                         <ColumnHighcharts chartData={healthChart} />
                         )}
                    </Card>
               </Grid>
               {/* <Grid item xs={12} sm={6}>
                    <Card sx={{ p: 1, boxShadow: "0px 0px 10px 1px #DBD8F1", height: '100%', maxHeight: '420px' }}>
                         {loading ? (<ChartLoader title="Loading..." />) : (
                         <ColumnHighcharts chartData={healthChart} />
                         )}
                    </Card>
               </Grid> */}
          </Grid>

     );
}