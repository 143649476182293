import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

const useStyles = makeStyles({

  indeterminateColor: {
    color: "#f50057"
  },

  selectAllText: {
    fontWeight: 'bold'
  },

  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },

  menuItems: {
    padding: '0px',
  },

});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
     maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
     width: 100,
    },
  },
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

export default function TableMultiSelect(props) {
  
  let data = props.dropdown;
  const columnDef = props.columnDef;
  const onFilterChanged = props.onFilterChanged;
  const [selected, setSelected] = useState(props.dropdown);

  const classes = useStyles();
  const isAllSelected = data.length > 0 && selected.length === data.length;

  const handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(
        selected.length === data.length
          ? []
          : data.map(item => {
              return item;
            }),
      );
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    onFilterChanged(columnDef.tableData.id, selected);
    // console.log(selected);
  }, [selected]);
  
  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (isAllSelected) return 'All';
          else if (selected.length >= 3) return selected.length + ' Selected';
          else return selected.join(', ');
        }}
        MenuProps={MenuProps}
        size="small"
      >
        <MenuItem
          className={classes.menuItems}
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                  selected.length > 0 && selected.length < data.length
              }
            />
          </ListItemIcon>
          <ListItemText
            // sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}
            classes={{ primary: classes.selectAllText }}
            sx={{
              whiteSpace: 'break-spaces',
              wordBreak: 'break-word',
              '.MuiListItemText-primary': { fontSize: '10px' },
            }}
            primary="Select All"
          />
        </MenuItem>

        {data.map((name, index) => (
          // console.log(name),
          <MenuItem key={index} value={name} className={classes.menuItems}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(name) > -1} />
            </ListItemIcon>
            <ListItemText
              // sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}
              sx={{
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                '.MuiListItemText-primary': { fontSize: '10px' },
              }}
              primary={name} 
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
  
}
