import React from 'react';
import config from "@jumbo/config";
import JumboThemeContext from "@jumbo/components/JumboTheme/JumboThemeContext";
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import JumboThemeHeader from "./JumboThemeHeader";
import JumboThemeSidebar from "./JumboThemeSidebar";
import JumboThemeFooter from "./JumboThemeFooter";

const JumboTheme = ({children, init}) => {
    const [theme, setTheme] = React.useState(init.main);
    const [muiLocale, setMuiLocale] = React.useState(config.locale);

    const updateTheme = React.useCallback((options) => {
        const newTheme = createTheme(adaptV4Theme({...theme, ...options}, muiLocale));
        setTheme(newTheme);
    }, [theme, setTheme]);

    const themeContextValue = React.useMemo(() => ({
        theme: createTheme(adaptV4Theme(theme, muiLocale)),
        setTheme: updateTheme,
        setMuiLocale: setMuiLocale,
    }), [theme, muiLocale]);

    return (
        <JumboThemeContext.Provider value={themeContextValue}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themeContextValue.theme}>
                    <JumboThemeHeader init={init.header}>
                        <JumboThemeSidebar init={init.sidebar}>
                            <JumboThemeFooter init={init.footer}>
                                {children}
                            </JumboThemeFooter>
                        </JumboThemeSidebar>
                    </JumboThemeHeader>
                </ThemeProvider>
            </StyledEngineProvider>
        </JumboThemeContext.Provider>
    );
};

export default JumboTheme;