import axios from '../config';

const Inventory = {

    getAllInventory: () => {

          return new Promise((resolve, reject) => {
               axios
                    .get('/inventory')
                    .then(({ data }) => {
                         resolve(data);

                         if (data.status === 'success') {
                            //   setMessage('Success');
                         } else {
                              reject(data);
                            //   setMessage(data.message);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                        //  setMessage('Error');
                    });
          });
    },
    
    getInventoryByDate: (date) => {

        return new Promise((resolve, reject) => {
             axios
                  .post('/inventory-by-date', {
                    "date": date
                  })
                  .then(({ data }) => {
                       resolve(data);

                       if (data.status === 'success') {
                          //   setMessage('Success');
                       } else {
                            reject(data);
                          //   setMessage(data.message);
                       }
                  })
                  .catch(function(error) {
                       reject(error);
                      //  setMessage('Error');
                  });
        });
    },

    getAllCategory: () => {

        return new Promise((resolve, reject) => {
             axios
                  .get('/category')
                  .then(({ data }) => {
                       resolve(data);

                       if (data.status === 'success') {
                          //   setMessage('Success');
                       } else {
                            reject(data);
                          //   setMessage(data.message);
                       }
                  })
                  .catch(function(error) {
                       reject(error);
                      //  setMessage('Error');
                  });
        });
  },

  getAllGraphData: (id, date) => {

    return new Promise((resolve, reject) => {
         axios
              .post('/material-code', {
                "materialCode": id,
                "startDate": date //"2024-04-08"
              })
              .then(({ data }) => {
                   resolve(data);

                   if (data.status === 'success') {
                      //   setMessage('Success');
                   } else {
                        reject(data);
                      //   setMessage(data.message);
                   }
              })
              .catch(function(error) {
                   reject(error);
                  //  setMessage('Error');
              });
    });
},
};

export default Inventory;