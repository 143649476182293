import React, { useEffect, useState } from "react";
import { Card, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function Metadata() {
   
    return(
        <Card sx={{ padding: '20px' }}>
            <h1 style={{ justifyContent: 'center', display: 'flex', color: '#006638' }}>Appendix</h1>
                <h3 style={{ color: '#73BF44' }}>
                •	Min and Max Inventory Stock value:
                </h3>
            
                    <h4 style={{ marginLeft: '20px' }}>
                    1.	For Material category A:
                    </h4>
                        <p style={{ marginLeft: '40px' }}>
                        a.	Minimum no of days stock requirement = 7 days <br/>
                        b.	Maximum no of days stock requirement = 21 days
                        </p>
                    <h4 style={{ marginLeft: '20px' }}>
                    2.	For Material category B:
                    </h4>
                        <p style={{ marginLeft: '40px' }}>
                        a.	Minimum no of days stock requirement = 3 days <br/>
                        b.	Maximum no of days stock requirement = 7 days
                        </p>

                <h3 style={{ color: '#73BF44', marginTop: '20px' }}>
                •	Actual and AOP based per day consumption values:
                </h3>

                    <h4 style={{ marginLeft: '20px' }}>
                    1.	AOP based per day consumption:
                    </h4>
                        <p style={{ marginLeft: '40px' }}>
                        Based on the Monthly AOP target, we have calculated the daily target production number. <br/>
                        <code style={{ backgroundColor: '#f1f1f1', padding: '2px', borderRadius: '3px' }}>Daily RM consumption based on AOP = (Monthly AOP MW number / No of Production days of that Month) </code>
                        </p>
                    <h4 style={{ marginLeft: '20px' }}>
                    2.	Actual based per day consumption:
                    </h4>
                        <p style={{ marginLeft: '40px' }}>
                        Based on the last 20 days average production achievement number, we have calculated the average daily RM consumption number. <br/>
                        <code style={{ backgroundColor: '#f1f1f1', padding: '2px', borderRadius: '3px' }}>Daily RM consumption based on Actual = sum (last 20 days production achievement MW)/ (20) </code>
                        </p>


        </Card>
    )
}