import axios from "../config";

const Auth = {
  login: (email, password, setMessage) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/user/login", {
          email: email,
          password: password,
        })
        .then(({ data }) => {
          // console.log(data);
          if (data.status === "success") {
            resolve(data);
            setMessage("Success");
          } else {
            reject(data);
            setMessage(data.message);
          }
        })
        .catch(function (error) {
          reject(error);
          setMessage("Error");
        });
    });
  },

  loginAdfs: (email, setMessage) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/user/adfsLogin", {
          email: email,
        })
        .then(({ data }) => {
          // console.log(data);
          if (data.status === "success") {
            resolve(data);
            setMessage("Success");
          } else {
            reject(data);
            setMessage(data.message);
          }
        })
        .catch(function (error) {
          console.log("logiing error", error);
          reject(error);
          setMessage("Error");
        });
    });
  },

  verifyToken: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/user/verify/token")
        .then(({ data }) => {
          // console.log(data);
          if (data.status === "success") {
            resolve(data);
          } else {
            resolve(null);
          }
        })
        .catch(function (error) {
          resolve(null);
        });
    });
  },

  changeUserPassword: (id, passwordData, setMessage) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/user/password/" + id, passwordData)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === "success") {
            resolve(data);
            setMessage("Success");
          } else {
            reject(data);
            setMessage(data.message);
          }
        })
        .catch(function (error) {
          reject(error);
          setMessage("Error");
        });
    });
  },
};

export default Auth;
