import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";


const Logo = ({mini, mode, sx}) => {
    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            <Link href={'/dashboard'}>
                {
                    !mini ?
                        <img style={{ width: '200px' }} src={ mode === "light" ? `/images/logo.png` : `/images/logo-white.png`} alt="Renew" />
                        :
                        <img style={{ width: '50px' }} src={mode === "light" ? `/images/logo-short.png` : `/images/logo-short-white.png`} alt="Renew" />
                }
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
