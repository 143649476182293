import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const menus = [
    {
        label: ' ',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'Home',
                type: "nav-item",
                icon: <HomeIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/users",
                // label: 'sidebar.menuItem.profile',
                label: 'Users',
                type: "nav-item",
                icon: <AccountBoxOutlinedIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/metadata",
                label: 'Metadata',
                type: "nav-item",
                icon: <FilePresentIcon sx={{fontSize: 20}}/>
            },
            // {
            //     uri: "/inventorytable",
            //     // label: 'sidebar.menuItem.profile',
            //     label: 'Inventory Table',
            //     type: "nav-item",
            //     icon: <AccountBoxOutlinedIcon sx={{fontSize: 20}}/>
            // },
        ]
    },

    // {
    //     label: 'sidebar.menu.apps',
    //     type: "section",
    //     children: [
    //         {
    //             uri: "/app/chats",
    //             label: 'sidebar.menuItem.chat',
    //             type: "nav-item",
    //             icon: <ChatOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             uri: "/app/contacts/all",
    //             label: 'sidebar.menuItem.contacts',
    //             type: "nav-item",
    //             icon: <ContactsOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             uri: "/app/mails/inbox",
    //             label: 'sidebar.menuItem.mail',
    //             type: "nav-item",
    //             icon: <EmailOutlinedIcon sx={{fontSize: 20}}/>
    //         }
    //     ]
    // },
    // {
    //     label: 'sidebar.menu.cards',
    //     type: "section",
    //     children: [
    //         {
    //             uri: "/widgets",
    //             label: 'sidebar.menuItem.widgets',
    //             type: "nav-item",
    //             icon: <WidgetsOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             uri: "/metrics",
    //             label: 'sidebar.menuItem.metrics',
    //             type: "nav-item",
    //             icon: <LeaderboardOutlinedIcon sx={{fontSize: 20}}/>
    //         }
    //     ]
    // },
    // {
    //     label: 'sidebar.menu.user',
    //     type: "section",
    //     children: [
    //         {
    //             uri: "/user/profile",
    //             label: 'sidebar.menuItem.profile',
    //             type: "nav-item",
    //             icon: <AccountBoxOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             uri: "/user/social-wall",
    //             label: 'sidebar.menuItem.socialWall',
    //             type: "nav-item",
    //             icon: <StreamOutlinedIcon sx={{fontSize: 20}}/>
    //         }
    //     ]
    // },
];

export default menus;
