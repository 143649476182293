export const msalConfig = {
  auth: {
    clientId: "bcce81ff-6671-484c-a8b2-7584f60530e5",
    authority:
      "https://login.microsoftonline.com/9df58381-d805-40c5-9ce9-b94ec70a870d",
    // redirectUri:
    // process.env.NODE_ENV === "development"
    //   ? "http://localhost:3000"
    //   : "https://imsm.renew.com",
    redirectUri: "http://localhost:3000",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["user.read", "openid", "profile"],
};
