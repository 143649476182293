import React, { forwardRef, memo, useEffect, useState } from 'react';
import MaterialTable, { MTableBodyRow, MTableHeader, MTableToolbar } from "@material-table/core";
import { AddBox, ArrowUpward, Check, ChevronLeft, ChevronRight, Clear, Delete, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@mui/icons-material';
import { ExportCsv } from '@material-table/exporters';
// import ExportPdf from '@material-table/exporters/pdf';
import { makeStyles } from '@mui/styles';
import { Box, Button, CircularProgress, FormControlLabel, InputLabel, Switch, TextField, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import { isUndefined } from 'lodash';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import AuthMethods from 'app/services';

const useStyles = makeStyles({

     tableText: {
          paddingLeft: '5px',
          fontSize: '14px',
          color: '#22334A',
          '@media (max-width: 400px)': {
               display: 'none',
          },
     },

      root: {
          "& .MuiTableCell-head": {
              color: "white",
              backgroundColor: "blue"
          },
      },

     errorMessage: {
          color: '#E73145 !important',
          fontSize: '13px',
          paddingBottom: '1px',
          minHeight: '15px'
     }

});

function addDaysToDate(dateStr, daysToAdd) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0]; // date in yyyy-mm-dd format
}

function CustomToolbar(props) {
     const { dateValue, setDateValue, isCustomColumnChangedAvailable, startDate, isAOP, setIsAOP, isInventoryTable } = props;

     return (
       <div>
         <MTableToolbar {...props} />
         {isInventoryTable && <p style={{ marginTop: '-15px', fontSize: '10px', padding: '0 24px' }}><span style={{ color: 'red' }}>*</span> Closing stock</p>}
         {isCustomColumnChangedAvailable && (
          <div style={{ fontFamily: `'Roboto', sans-serif`, width: '100%', display: 'flex', marginBottom: '10px', padding: '0 24px 0 24px' }}>
                    <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                         <h3>Actual</h3>
                              <Switch
                              checked={isAOP}
                              onClick={() => setIsAOP(prev => !prev)}
                              name="checkedB"
                              color="primary"
                              />
                         <h3>AOP</h3>
                    </div>     
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                              {/* <h4>Custom Input Date:</h4> */}
                              <DatePicker
                              sx={{ width: '80%' }}
                                   value={dateValue}
                                   label="Custom Input Date"
                                   onChange={(newValue) => setDateValue(newValue)}
                                   minDate={dayjs(startDate)}
                                   maxDate={dayjs(startDate).add(60, 'day')}
                                   format="YYYY/MM/DD"
                                   
                              />
                         </LocalizationProvider>
                    </div>
               
          </div>
           
         )}
       </div>
     );
   }

export default memo(function SolarTable(props) {

     // console.log(props);
     const classes = useStyles();
     const [dateValue, setDateValue] = React.useState(null);
     // const [dateValue, setDateValue] = useState(dayjs(props.startDate).add(29, 'day'));

     const [isAOP, setIsAOP] = useState(false);
     
     useEffect(() => {
          if(props.isInventoryTable) {
               function fetchAndSetInventoryData(startDate) {
                    const dates = {
                      sevenDays: addDaysToDate(startDate, 6),
                      fourteenDays: addDaysToDate(startDate, 13),
                      twentyOneDays: addDaysToDate(startDate, 20),
                      twentyEightDays: addDaysToDate(startDate, 27),
                    };
                  
                    AuthMethods['inventory'].getInventoryByDate(startDate).then(data => {
                      if (data.length > 0) {
                        let temp = [];
                        let mappedData = [];
                        let mappedDatum = [];
      
                        for (let i = 0; i < data.length; i++) {
                          const promises = [
                            AuthMethods['inventory'].getInventoryByDate(dates.sevenDays),
                            AuthMethods['inventory'].getInventoryByDate(dates.fourteenDays),
                            AuthMethods['inventory'].getInventoryByDate(dates.twentyOneDays),
                            AuthMethods['inventory'].getInventoryByDate(dates.twentyEightDays),
                          ];
                  
                          Promise.all(promises).then(([sevenDaysData, fourteenDaysData, twentyOneDaysData, twentyEightDaysData]) => {
                            mappedData = data[i].data.map((item, index) => {
                              return {
                                id: index,
                                date: data[i].date,
                                plant_name: data[i].plant_name,
                                material_category: item.Material_Category,
                                material_code: item.Material_Code,
                                material_description: item.Material_Description,
                                module_type: item.Module_Type,
                                stock_qty: parseFloat(item['Unrestricted_Stock_Qty'] || 0), 
                                stock: isAOP ? parseFloat(item['Unrestricted_Stock_MW_AOP'] || 0) : parseFloat(item['Unrestricted_Stock_MW'] || 0),
        
                                seven_days: sevenDaysData && typeof sevenDaysData === 'object' && sevenDaysData.length > i ? (isAOP ? parseFloat(sevenDaysData[0].data[index]['Unrestricted_Stock_MW_AOP'] || 0) : parseFloat(sevenDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0)) : '--',
                                fourteen_days: fourteenDaysData.length > i ? (isAOP ? parseFloat(fourteenDaysData[0].data[index]['Unrestricted_Stock_MW_AOP'] || 0) : parseFloat(fourteenDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0)) : '--',
                                twenty_one_days: twentyOneDaysData.length > i ? (isAOP ? parseFloat(twentyOneDaysData[0].data[index]['Unrestricted_Stock_MW_AOP'] || 0) : parseFloat(twentyOneDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0)) : '--',
                                twenty_eight_days: twentyEightDaysData.length > i ? (isAOP ? parseFloat(twentyEightDaysData[0].data[index]['Unrestricted_Stock_MW_AOP'] || 0) : parseFloat(twentyEightDaysData[0].data[index]['Unrestricted_Stock_MW'] || 0)) : '--',
                                
                                seven_min: sevenDaysData.length > i ? (isAOP ? parseFloat(sevenDaysData[0].data[index]['Min_Inventory_Required_MW_AOP'] || 0) : parseFloat(sevenDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0)) : null,
                                fourteen_min: fourteenDaysData.length > i ? (isAOP ? parseFloat(fourteenDaysData[0].data[index]['Min_Inventory_Required_MW_AOP'] || 0) : parseFloat(fourteenDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0)) : null,
                                twenty_one_min: twentyOneDaysData.length > i ? (isAOP ? parseFloat(twentyOneDaysData[0].data[index]['Min_Inventory_Required_MW_AOP'] || 0) : parseFloat(twentyOneDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0)) : null,
                                twenty_eight_min: twentyEightDaysData.length > i ? (isAOP ? parseFloat(twentyEightDaysData[0].data[index]['Min_Inventory_Required_MW_AOP'] || 0) : parseFloat(twentyEightDaysData[0].data[index]['Min_Inventory_Required_MW'] || 0)) : null,
      
                                
                                seven_max: sevenDaysData.length > i ? (isAOP ? parseFloat(sevenDaysData[0].data[index]['Max_Inventory_Required_MW_AOP'] || 0) : parseFloat(sevenDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0)) : null,
                                fourteen_max: fourteenDaysData.length > i ? (isAOP ? parseFloat(fourteenDaysData[0].data[index]['Max_Inventory_Required_MW_AOP'] || 0) : parseFloat(fourteenDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0)) : null,
                                twenty_one_max: twentyOneDaysData.length > i ? (isAOP ? parseFloat(twentyOneDaysData[0].data[index]['Max_Inventory_Required_MW_AOP'] || 0) : parseFloat(twentyOneDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0)) : null,
                                twenty_eight_max: twentyEightDaysData.length > i ? (isAOP ? parseFloat(twentyEightDaysData[0].data[index]['Max_Inventory_Required_MW_AOP'] || 0) : parseFloat(twentyEightDaysData[0].data[index]['Max_Inventory_Required_MW'] || 0)) : null,
                              };
                            });
      
                          //   temp.push(...mappedData)
      
                          //   console.log(dateValue)
                            let isDateSet = false;
                            let formattedDate = dateValue ? dateValue.format("YYYY-MM-DD") : dayjs(props.startDate).format("YYYY-MM-DD");
      
                          //   if(!dateValue) {
                          //      formattedDate = dateValue ? dateValue.format("YYYY-MM-DD") : dayjs(props.startDate).format("YYYY-MM-DD");
                          //   } else {
                          //      isDateSet = true
                          //   }
                          //   console.log(formattedDate)
      
                            if(formattedDate) {
                               AuthMethods['inventory'].getInventoryByDate(formattedDate).then(data => {
                                  if (data.length > 0 ) {
                                    if(true) {
                                         for(let i = 0; i< data.length; i++) {
                                              mappedDatum = mappedData.map((item, index) => {
                                                  return {
                                                       ...item,
                                                       custom_days: (isAOP ? parseFloat(data[0].data[index]['Unrestricted_Stock_MW_AOP'] || 0) : parseFloat(data[0].data[index]['Unrestricted_Stock_MW'] || 0)),
                                                       custom_min: (isAOP ? parseFloat(data[0].data[index]['Min_Inventory_Required_MW_AOP'] || 0) : parseFloat(data[0].data[index]['Min_Inventory_Required_MW'] || 0)),
                                                       custom_max: (isAOP ? parseFloat(data[0].data[index]['Max_Inventory_Required_MW_AOP'] || 0) : parseFloat(data[0].data[index]['Max_Inventory_Required_MW'] || 0))
                                                  }
                                                });
      
                                              temp.push(...mappedDatum)
                                          }
                                    }
      
                                    //to set all subsequent fields to null if -ve value found
                                    temp.forEach(item => {
                                         let fields = ['seven_days', 'fourteen_days', 'twenty_one_days', 'twenty_eight_days'];
                                         let minfields = ['seven_min', 'fourteen_min', 'twenty_one_min', 'twenty_eight_min'];
                 
                                         for (let i = 0; i < fields.length; i++) {
                                             // if (parseFloat(item[fields[i]]) < 0) {
                                             if (parseFloat(item[fields[i]]) < parseFloat(item[minfields[i]])) {
                                                 for (let j = i + 1; j < fields.length; j++) {
                                                     item[fields[j]] = '';
                                                 }
                                                 break;
                                             }
                                         }
                                     });
                                    props.setTableData(temp);
         
                                  }
                               });
                            }
      
                          }).catch(error => {
                            console.error('Error fetching additional inventory data:', error);
                          });
                        }
                      }
                    }).catch(error => {
                      console.error('Error fetching initial inventory data:', error);
                    });
                  }
        
                  fetchAndSetInventoryData(props.startDate);
          }
          
      },[dateValue, isAOP])

      useEffect(() => {
     //to set bg color to white on initial render for custom input
     if(props.setIsCustomColumnChanged) {
          if(dateValue) {
               props.setIsCustomColumnChanged(true);
          }
     }

     // AuthMethods['inventory'].getInventoryByDate(formattedDate).then(data => {
     //    if (data.length > 0 ) {
     //        let temp = [];

     //        for(let i = 0; i< data.length; i++) {
     //            const mappedData = props.data.map((item, index) => {
     //                return {
     //                     ...item,
     //                     custom_days: (isAOP ? data[0].data[index]['Unrestricted_Stock_MW_AOP'] : data[0].data[index]['Unrestricted_Stock_MW']),
     //                     custom_min: (isAOP ? data[0].data[index]['Min_Inventory_Required_MW_AOP'] : data[0].data[index]['Min_Inventory_Required_MW'])
     //                }
     //              });
                
     //            temp.push(...mappedData)
     //        }
     //      props.setTableData(temp);
     //    }
     // });
     }, [dateValue, isAOP])

     const icons = {
          Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
          DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} color="secondary" />),
          Edit: forwardRef((props, ref) => <Edit color="primary" {...props} ref={ref} />),
          Export: forwardRef((props, ref) =>
          <React.Fragment>
               <SaveAlt {...props} ref={ref} color="primary" />
               <span className={classes.tableText}>Export</span>
          </React.Fragment> 
          ),
          Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
          FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
          LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
          NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
          ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
          ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
          ViewColumn: forwardRef((props, ref) => 
               <React.Fragment>
                    <ViewColumn {...props} ref={ref} color="primary" />
                    <span className={classes.tableText}>Customize Table</span>
               </React.Fragment>
          ),
          Delete: forwardRef((props, ref) => <Delete color="primary" {...props} ref={ref} />),
     };

     const dataKey = props.data.length.toString();
     console.log(props.data)
     return (

          <MaterialTable
               style={{
                    padding: props.setHeight ? '6px' : '10px',
                    borderRadius: '10px',
                    boxShadow: '1px 1px 15px 3px #DBD8F1',
                  }}
               sx={{
                    "& .MuiTableCell-head": {
                         textAlign: 'center',
                     },
               }}
               title={
                    <Typography
                         sx={{ 
                         // color: theme => theme.palette.text.primary,
                         fontSize: '16px',
                         fontWeight: 'bold'
                         }}
                    >
                         {props.title}
                    </Typography>
               }
               columns={props.columns}
               key={dataKey}
               data={props.data}
               onSelectionChange = {props.onSelectionChange}
               icons={icons}
               isLoading={props.loading}
               options={{
                    headerStyle: {
                         backgroundColor: '#a5d686',
                         height: '46px',
                         fontWeight: 'bold',
                         fontSize: '14px',
                         whiteSpace: (props.spacing) ? 'nowrap' : '',
                         // padding: props.selection === true ? '' : '0 10px 0 10px',
                         // border: '1px solid #ddd', 
                    },
                    rowStyle: {
                         // textAlign: 'inherit',
                         // padding: (props.selection && props.sendMail) ? '6px 0 6px 25px' : '3px 0 3px 10px',
                    },
                    search: !isUndefined(props.search) ? props.search : props.insideDetailPanel ? false : true,
                    searchFieldStyle: {
                         backgroundColor: 'whitesmoke',
                         borderRadius: '40px',
                         padding: '4px',
                    },
                    filterCellStyle: {
                         padding: '3px',
                    },
                    actionsColumnIndex: -1,
                    columnsButton: isUndefined(props.columnsButton) ? true : props.columnsButton,
                    exportAllData: true,
                    exportMenu: props.export ? (
                         [
                              {
                                   label: "Export CSV",
                                   exportFunc: (cols, datas) => {
                                        if (props.exportFunc) {
                                             props.exportFunc();
                                        } else {
                                             ExportCsv(cols, datas, "tableData");
                                        }
                                   },
                              },
                              // {
                              //   label: "Export PDF",
                              //   exportFunc: (cols, datas) => ExportPdf(cols, datas, "pdfFileName"),
                              // },
                         ]) : [],
                    filtering: props.filtering,
                    selection: props.selection ? props.selection : false,
                    // showTextRowsSelected: props.selection ? props.selection : true,
                    selectionProps: props.selectionProps ? props.selectionProps : {},
                    tableLayout: props.tableLayout ? props.tableLayout : {},
                    paging: !isUndefined(props.paging) ? props.paging : true,
                    emptyRowsWhenPaging: false,
                    pageSize: props.pageSize ? props.pageSize : 10,
                    pageSizeOptions: props.pageSizeOptions ? [15, 30, 45] : [10, 20, 30],
                    maxBodyHeight: props.setHeight ? 340: '',
               }}
               components={{
                    // Toolbar: props => (
                    //      <div>
                    //        <MTableToolbar {...props} />
                    //        {props.setIsCustomColumnChanged ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //            <DatePicker
                    //                 value={dateValue}
                    //                 label={'YYYY/MM/DD'}
                    //                 onChange={(newValue) => {setDateValue(newValue)}}
                    //                 minDate={dayjs(props.startDate)}
                    //                 format="YYYY/MM/DD" 
                    //            />
                    //        </LocalizationProvider> : <div></div>}
                           

                    //      </div>
                    // ),
                    Toolbar: toolbarProps => (
                         <CustomToolbar
                           {...toolbarProps}
                           dateValue={dateValue}
                           setDateValue={setDateValue}
                           isCustomColumnChangedAvailable={!!props.setIsCustomColumnChanged}
                           startDate={props.startDate}
                           setTableData={props.setTableData}
                           isAOP={isAOP}
                           setIsAOP={setIsAOP}
                           isInventoryTable={props.isInventoryTable}
                         />
                       ),
                    OverlayLoading: props => (
                         <Div
                              sx={{
                                   width: '100%',
                                   height: '100%',
                                   display: 'flex',
                                   justifyContent: 'center',
                                   alignItems: 'center'
                              }}
                         >
                              <CircularProgress/>
                         </Div>
                    ),
               }}
               actions={props.actions.length !== 0 ? [...props.actions] : []}
               detailPanel={props.detailPanel.length !== 0 ? [...props.detailPanel] : []}
          />
     );
});
